.status {
    white-space: nowrap;
    padding: 4px 8px;
    border-radius: 2px;
}
body.overflow {
  overflow: visible !important;
}
.printTable {
  table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
  table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: inherit;
}

&.fixedHeight {
  table.md-table tbody.md-body > tr.md-row, table.md-table tfoot.md-foot > tr.md-row {
    height: 30px;
  }
}
}

#print-pagos {

  .info-heading {
    span {
      font-size: 12px;
      margin-right: 12px;
    }
  }

  /* Serial column*/
  table.md-table:not(.md-row-select) td.md-cell:first-child, table.md-table:not(.md-row-select) th.md-column:first-child {
    /* padding: 0 24px; */
    padding-left: 6px;
    padding-right: 0;
    text-align: center;
  }

  @media print {
    @page {
        margin: 1cm;
    }
    tr {
      height:23px;
    }
    th {
      font-size: 10px;
    }
    td {
      font-size: 10px;
    }
  }
}

#cutoff-list {
  .secondToolbar {
    .indicator {
      span {
        font-size: 12px;
        margin-right: 12px;
      }
    }
  }

  .small-row {
    height: 35px;
  }

  .center {
      .header {
          height: 136px !important;
          min-height: 136px !important;
          max-height: 136px !important;
          padding: 24px 0;

          .h1 {
              md-icon {
                  margin-right: 12px;
              }
          }

      }
      .content-card {
          .toolbar {
              padding: 8px;
          }
          .content {
              padding: 0;
          }
      }
  }
}

@media print {
  /* put your CSS rules if they are to apply in print only */
  @page {
    margin: 1cm;
  }
}

#e-commerce-orders {

    .center {

        .header {
            height: 136px !important;
            min-height: 136px !important;
            max-height: 136px !important;
            padding: 24px 0;

            .h1 {

                md-icon {
                    margin-right: 12px;
                }
            }

            .order-count {
                margin: 6px 0 0 36px;
            }

            .search {
                position: relative;
                padding-left: 24px;
                max-width: 480px;

                .search-icon {
                    margin: 0 -24px 0 0;
                }

                .search-input {
                    margin: 0 0 0 32px;
                    background: none;
                    font-size: 16px;
                    line-height: 40px;
                    height: 40px;
                    color: #FFFFFF;
                    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
                    transition: border-color 300ms ease;

                    &:focus {
                        border-color: rgba(255, 255, 255, 1);
                    }

                    &::-webkit-input-placeholder {
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }

        }

        .content-card {

            .toolbar {
                padding: 8px;

                .selected-project {
                    background: rgba(0, 0, 0, 0.45);
                    color: #FFFFFF;
                    padding: 8px 16px;
                    height: 40px;
                    line-height: 24px;
                    font-size: 14px;
                }

                .project-selector {
                    margin-left: 1px;
                    border-radius: 0;
                    background: rgba(0, 0, 0, 0.45);

                    md-icon {
                        color: #FFFFFF;
                    }
                }
            }

            .content {
                padding: 0;

                .dataTables_wrapper {

                    .dataTables_scrollHead {
                        min-height: 64px;

                        table {

                            th {
                                padding-top: 20px;
                                padding-bottom: 20px;
                            }
                        }
                    }

                    .dataTables_scrollBody {

                        table {

                            .status {
                                font-size: 12px;
                                white-space: nowrap;
                                padding: 2px 5px;
                                border-radius: 2px;
                            }
                        }

                        md-icon {
                          font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}
