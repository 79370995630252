md-dialog {

    &.contact-dialog {
        max-width: 80%;
        width: 80%;

        md-toolbar {

            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;

            md-input-container {
                margin-top: 30px;
            }

            md-icon {
                color: rgba(0, 0, 0, 0.87);
                margin: 0 8px 0 0;
            }

        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}
