> .header {
    height: 120px;
    min-height: 120px;
    max-height: 120px;

    .goto-orders-button {
        margin-right: 16px;
    }

    .subtitle {
        margin: 6px 0 0 0;
    }
}

.card-container {
    padding: 24px;
    margin-bottom: 24px;

    .status {
        white-space: nowrap;
        padding: 4px 8px;
        border-radius: 2px;
    }

    &.list {
        padding: 0;

        md-list {
          padding-left: 24px;
          padding-right: 8px;

          md-subheader-inner {
            padding: 0px;
          }

        }
    }

}

.inputLikeText {
  small, span {
    display: block;
    margin-bottom: 5px;
  }
  small {
    color: rgba(0, 0, 0, .5);
  }
}

#wizard {
    .existing-borrowers {
      margin-bottom: 10px;
      .first-line {
        margin-bottom: 10px;
      }
    }
}

#prestamo-single {

  > .header {
      height: 120px;
      min-height: 120px;
      max-height: 120px;

      .goback-button {
          margin-right: 16px;
      }

      .subtitle {
          margin: 6px 0 0 0;
      }
  }

  .print {
    display: none;
  }

  .card-container {
      padding: 24px;
      margin-bottom: 24px;

      .status {
          white-space: nowrap;
          padding: 4px 8px;
          border-radius: 2px;
      }

      &.list {
          padding: 0;
      }
  }

  @media print {
    @page {
        margin: 1cm;
    }

    .header {
      .h2 {
        color: black !important;
      }
      .subtitle {
        &.secondary-text {
          color: black !important;
        }
      }
      md-input-container {
        color: black;
      }
    }

    .print {
      display: inherit;
    }

    md-tabs-wrapper {
      box-shadow: none;
      display: none;
    }

    table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
    table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
    padding: inherit;
    }

    .card-container {
      box-shadow: none;
      padding: 0;
    }

    .no-print{
      display: none;
    }
  }
}

#print-prestamos {

  @media print {
    @page {
        margin: 1cm;
    }
    tr {
      height:23px;
    }
    th {
      font-size: 10px;
    }
    td {
      font-size: 10px;
      .status {
        color: black;
      }
      .longname {
        overflow: hidden;
        max-width: 20px;
        width: 20px;
      }
    }
  }
}

#contacts {
    position: relative;

    > .header {
        height: 76px;
        min-height: 76px;
        max-height: 76px;

        .logo {

            .logo-icon {
                margin: 0 16px 0 0;
            }

            .logo-text {
                font-size: 24px;
            }
        }

        md-icon {
            color: #FFFFFF;
        }

        .search-input-wrapper {
            position: relative;

            label {
                padding-right: 4px;
                cursor: pointer;

                md-icon {
                    color: rgba(255, 255, 255, 0.8);
                }

            }

            md-input-container {

                .md-errors-spacer {
                    display: none;
                }

                input {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                    color: rgba(255, 255, 255, 0.8);
                    width: 240px;

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.8);
                    }

                    &:focus {
                        border-bottom: 1px solid white;
                        color: white;
                    }
                }
            }
        }

        &.selected-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 999;
            background-image: none;
            opacity: 1 !important;

            .close-button-wrapper {
                width: 244px;
            }
        }
    }

    > .content {
        position: absolute;
        top: 76px;
        bottom: 0;
        right: 0;
        left: 0;

        > md-sidenav {
            box-shadow: $whiteframe-shadow-1dp;
            padding: 0;
        }

        .main {
            padding: 16px 90px 0 4px;
            position: absolute;
            top: 0;
            left: 24px;
            right: 0;
            bottom: 0;

            .contacts-list {
                position: relative;
                padding-bottom: 0;

                .md-subheader {
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.14);

                    ._md-subheader-inner {
                        padding: 16px;
                    }

                    .contacts-count {
                        padding-left: 4px;
                    }

                    .list-title {
                        margin-right: 4px;
                    }

                    .edit-group-form {

                        .editable-controls {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;

                            .editable-input {
                                border: 1px solid rgba(0, 0, 0, 0.2);
                                padding: 4px 6px;
                                margin-right: 6px;
                                font-size: 13px;
                            }

                            .editable-buttons {

                            }
                        }

                        .edit-group-button {
                            margin: 0;
                        }

                        .md-button {
                            margin: 0;
                            margin: 0;
                            padding: 0;
                            height: 32px;
                            min-height: 32px;
                            line-height: 32px;
                            width: 32px;
                        }
                    }
                }

                .contact-item {
                    position: absolute;
                    right: 0;
                    left: 0;
                    width: 100%;
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                    background: white;
                    transition: transform 200ms ease-out;
                    will-change: transform;

                    &:last-of-type {
                        margin-bottom: 24px;
                    }

                    &.ng-leave {
                        transform: translateY(0);
                        display: none;

                        &.ng-leave-active {
                            transform: translateY(-100%);
                        }
                    }

                    &.ng-hide {
                        display: none;
                    }

                    &.ng-hide-add-active,
                    &.ng-hide-remove-active {
                        display: block;
                        transition-property: all;
                    }
                }
            }

            .no-results {
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
            }
        }
    }

    #add-contact-button {
        position: fixed;
        bottom: 8px;
        right: 8px;
        padding: 0;
    }
}

// RESPONSIVE
@media screen and (min-width: $layout-breakpoint-md) {

    #contacts {

        > .content {

            .main {
                left: 268px;
            }
        }
    }
}
