.free-layout {
  margin-top: 32px;
}
.form-title {
  h2 {
    margin-top: 0px;
    display: inline-block;
    width: 100%;
  }
}
md-input-container.transparentFix:not(.md-input-has-value) input:not(:focus) {
  color: #000;
}
md-toolbar.md-table-toolbar.alternate {
  .md-toolbar-tools {
    md-icon {
      color: #fff;

      &:hover {
        color: #eee;
      }
    }
  }
}

#e-commerce-orders {

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: inline;
      background: material-color('light-blue', '600');
      color: #fff;
      padding: 5px 10px;
      margin-right: 5px;

      &:last-child {
        margin: 0;
      }

      &.admin {
        background: #000;
      }
    }
  }

    .center {

        .header {
            height: 136px !important;
            min-height: 136px !important;
            max-height: 136px !important;
            padding: 24px 0;

            .h1 {

                md-icon {
                    margin-right: 12px;
                }
            }

            .order-count {
                margin: 6px 0 0 36px;
            }

            .search {
                position: relative;
                padding-left: 24px;
                max-width: 480px;

                .search-icon {
                    margin: 0 -24px 0 0;
                }

                .search-input {
                    margin: 0 0 0 32px;
                    background: none;
                    font-size: 16px;
                    line-height: 40px;
                    height: 40px;
                    color: #FFFFFF;
                    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
                    transition: border-color 300ms ease;

                    &:focus {
                        border-color: rgba(255, 255, 255, 1);
                    }

                    &::-webkit-input-placeholder {
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }

        .content-card {

            .toolbar {
                padding: 8px;
            }

            .content {
                padding: 0;

                .dataTables_wrapper {

                    .dataTables_scrollHead {
                        min-height: 64px;

                        table {

                            th {
                                padding-top: 20px;
                                padding-bottom: 20px;
                            }
                        }
                    }

                    .dataTables_scrollBody {

                        table {

                            .status {
                                font-size: 12px;
                                white-space: nowrap;
                                padding: 2px 5px;
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
